import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { paths } from "./config/paths"
import Franklin from "./pages/Franklin"
import Landing from "./pages/Landing"
import MeditationInMotion from "./pages/MeditationInMotion"
import Team from "./pages/Team"
import ViaIntegralis from "./pages/ViaIntegralis"
import "./styles/styles.css"

function App() {
  const router = createBrowserRouter([
    {
      path: paths.home,
      element: <Landing></Landing>,
    },
    {
      path: paths.franklin,
      element: <Franklin></Franklin>,
    },
    {
      path: paths.meditationInMotion,
      element: <MeditationInMotion></MeditationInMotion>,
    },
    {
      path: paths.viaIntegralis,
      element: <ViaIntegralis></ViaIntegralis>,
    },
    {
      path: paths.team,
      element: <Team></Team>,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
