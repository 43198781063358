import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Box, Button, Container, Drawer, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { paths } from "../config/paths"
import { NavButton } from "./NavButton"

export default function Appbar() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)

  return (
    <AppBar position="static" sx={{ mb: 5 }} color="inherit" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ alignItems: "center", gap: 4, px: { xs: 4, sm: 8, md: 16 } }}>
          <Box sx={{ display: "flex", gap: 4, flexGrow: 1, alignItems: "center" }}>
            <NavLink to={paths.home}>
              <Button
                disableRipple
                disableTouchRipple
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "black",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Meditieren & Bewegen
              </Button>
            </NavLink>

            {!isMobile && (
              <>
                <NavButton to={paths.meditationInMotion} label={"Meditation in Bewegung"}></NavButton>
                <NavButton to={paths.franklin} label={"Franklin Methode"}></NavButton>
                <NavButton to={paths.viaIntegralis} label={"Via Integralis"}></NavButton>
                <NavButton to={paths.team} label={"Team"}></NavButton>
              </>
            )}
          </Box>

          {isMobile && (
            <>
              <IconButton onClick={() => setOpen(true)}>
                <MenuIcon></MenuIcon>
              </IconButton>

              <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <Toolbar sx={{ justifyContent: "end", px: 8 }}>
                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon></CloseIcon>
                  </IconButton>
                </Toolbar>

                <Box sx={{ px: 8, display: "flex", gap: 4, flexDirection: "column", alignContent: "start" }}>
                  <NavButton fullWidth to={paths.meditationInMotion} label={"Meditation in Bewegung"}></NavButton>
                  <NavButton fullWidth to={paths.franklin} label={"Franklin Methode"}></NavButton>
                  <NavButton fullWidth to={paths.viaIntegralis} label={"Via Integralis"}></NavButton>
                  <NavButton fullWidth to={paths.team} label={"Team"}></NavButton>
                </Box>
              </Drawer>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
