import { Box, Typography } from "@mui/material"
import { blue } from "@mui/material/colors"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import CardBox from "../components/CardBox"
import List from "../components/List"
import MyCard from "../components/MyCard"
import PageBox from "../components/PageBox"
import { persons } from "../data/persons"
export default function Team() {
  return (
    <PageBox title="Team">
      <CardBox>
        {persons.map((person, personIndex) => (
          <MyCard key={personIndex} image={person.image} title={person.name}>
            <Typography variant="h2" color={blue[800]} mb={2}>
              {person.name}
            </Typography>
            <Box sx={{ mb: 4 }}>
              <List items={person.introductions.map((i) => ({ title: "", text: i }))}></List>
            </Box>

            <Grid container spacing={2}>
              <Grid md={2}>E-Mail</Grid>
              <Grid md={10}>{person.email}</Grid>
              {person.mobile && (
                <>
                  <Grid md={2}>Telefon</Grid>
                  <Grid md={10}>{person.mobile}</Grid>
                </>
              )}
            </Grid>
          </MyCard>
        ))}
      </CardBox>
    </PageBox>
  )
}
