import { Box, Card } from "@mui/material"

interface CardProps {
  children: JSX.Element | JSX.Element[]
  image?: string
  title?: string
}

export default function MyCard({ children, image, title }: CardProps) {
  return (
    <Card
      elevation={0}
      sx={{
        py: 8,
        px: { xs: 8, md: 16 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 8,
      }}
    >
      {image && (
        <Box
          sx={{
            borderRadius: 4,
            overflow: "hidden",
            alignSelf: "start",
            width: 256,
            height: 256,
            minWidth: 256,
          }}
        >
          <img src={image} width="256" height="256" alt={title}></img>
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Card>
  )
}
