import { Box, Typography, useTheme } from "@mui/material"
import { blue } from "@mui/material/colors"
import useMediaQuery from "@mui/material/useMediaQuery"
import Appbar from "../components/Appbar"
import CardBox from "../components/CardBox"
import TeaserCard from "../components/TeaserCard"
import { paths } from "../config/paths"

export default function Landing() {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box sx={{ pb: { xs: 20, md: 30 }, mx: "auto" }} maxWidth="md">
      <Appbar></Appbar>

      <Box sx={{ position: "relative" }}>
        <img src="images/Stones.jpg" width={isMd ? "900px" : "100%"} alt="Monika Helbling und Christian Frei"></img>

        <Box sx={{ position: "absolute", top: { xs: 16, sm: 32, md: 40 }, left: 0, right: 0 }}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="h1" sx={{ fontSize: { xs: "32px", sm: "40px", md: "48px" }, color: "white" }}>
              Meditieren & Bewegen
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: "32px", sm: "40px", md: "48px" }, color: "white" }}>
              mit
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 8 }}>
          <CardBox>
            <TeaserCard title="Meditation in Bewegung" image="images/VollDaGanzWeg.png" to={paths.meditationInMotion}>
              <Typography variant="h2" color={blue[800]} mb={2}>
                Meditation in Bewegung
              </Typography>
              <Typography variant="body1">Lass deine Gedanken los und finde Ruhe</Typography>
            </TeaserCard>

            <TeaserCard title="Franklin-Methode" image="images/Franklin.jpg" to={paths.franklin}>
              <Typography variant="h2" color={blue[800]} mb={2}>
                Franklin-Methode®
              </Typography>
              <Typography variant="body1">Effizientes Körpertraining</Typography>
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                koordiniert - entspannt - kraftvoll
              </Typography>
            </TeaserCard>

            <TeaserCard title="Via Integralis" image="images/Via_Integralis2.jpg" to={paths.viaIntegralis}>
              <Typography variant="h2" color={blue[800]} mb={2}>
                Via Integralis
              </Typography>
              <Typography variant="body1">
                Bei den schweigenden Meditationseinheiten im Sitzen meditieren wir nach dieser Schule. Sie wurde von
                Zen-Meisterin Pia Gyger und Zen-Meister P. Niklaus Brantschen (SJ) entwickelt als Frucht ihres
                interreligiösen Wegs. Dieser Schulungsweg verbindet die christliche Mystik mit der Übung des Zazen, der
                gegenstandslosen Schweigemeditation des Zenbuddhismus.
              </Typography>
            </TeaserCard>
          </CardBox>
        </Box>
      </Box>
    </Box>
  )
}
