import Typography from "@mui/material/Typography"
import CourseSection from "../components/CourseSection"
import List from "../components/List"
import PageBox from "../components/PageBox"
import SectionBox from "../components/SectionBox"
import { tertianum } from "../data/locations"
import { monika } from "../data/persons"
import { ICourse } from "../interfaces/ICourse"

export default function Franklin() {
  const courses: ICourse[] = [
    {
      title: "Bewegungstraining 55+ 09:10  (Start Juni 2024)",
      description: [],
      type: "Kurs",
      occurances: [
        { start: new Date(2024, 5, 4, 9, 10), end: new Date(2024, 5, 4, 10, 0) },
        { start: new Date(2024, 5, 11, 9, 10), end: new Date(2024, 5, 11, 10, 0) },
        { start: new Date(2024, 5, 18, 9, 10), end: new Date(2024, 5, 18, 10, 0) },
        { start: new Date(2024, 5, 25, 9, 10), end: new Date(2024, 5, 25, 10, 0) },
        { start: new Date(2024, 6, 2, 9, 10), end: new Date(2024, 6, 2, 10, 0) },
        { start: new Date(2024, 7, 13, 9, 10), end: new Date(2024, 7, 13, 10, 0) },
        { start: new Date(2024, 7, 20, 9, 10), end: new Date(2024, 7, 20, 10, 0) },
        { start: new Date(2024, 7, 27, 9, 10), end: new Date(2024, 7, 27, 10, 0) },
      ],
      person: [monika],
      location: tertianum,
      price: {
        amount: "180",
        paymentType: "prepayment",
        info: "Einzelstunde 25CHF",
      },
      // registrationUntil: { date: new Date(2024, 5, 4) },
    },
    // {
    //   title: "Workshop Franklin-Methode®",
    //   description: ["koordiniert - entspannt - kraftvoll", "effizientes Bewegen erleben"],
    //   type: "Workshop",
    //   location: gsteigtobel,
    //   person: [monika],
    //   price: { amount: 50, paymentType: "on-site", info: "pro Workshop oder gesamter Workshop für 180 CHF" },
    //   registrationUntil: { date: new Date(2024, 0, 28), info: "von allen Workshops, oder 5 Tage vor den Einzelnen." },
    //   occurances: [
    //     { start: new Date(2024, 1, 2, 18, 30), end: new Date(2024, 1, 2, 20, 30), info: "Becken" },
    //     { start: new Date(2024, 1, 14, 18, 30), end: new Date(2024, 1, 14, 20, 30), info: "Fuss" },
    //     { start: new Date(2024, 2, 6, 18, 30), end: new Date(2024, 2, 6, 20, 30), info: "Wirbelsäule" },
    //     { start: new Date(2024, 2, 22, 18, 30), end: new Date(2024, 2, 22, 20, 30), info: "Schultergelenk" },
    //   ],
    // },
    // {
    //   title: "Einführung in die Franklin-Methode®",
    //   description: ["Effizientes Bewegen erleben mit Vorstellungskraft & erlebter Anatomie"],
    //   type: "Lektion",
    //   location: gsteigtobel,
    //   person: [monika],
    //   participants: 4,
    //   price: { amount: 30, paymentType: "on-site", info: "pro Lektion (60min)" },
    //   registrationUntil: { info: "Zwei Tage im Voraus" },
    //   occurances: [
    //     { start: new Date(2023, 5, 7, 19), end: new Date(2023, 5, 7, 20, 15) },
    //     { start: new Date(2023, 5, 21, 19), end: new Date(2023, 5, 21, 20, 15) },
    //     { start: new Date(2023, 5, 28, 19), end: new Date(2023, 5, 28, 20, 15) },
    //   ],
    // },
  ]
  return (
    <PageBox title="Franklin-Methode®" image="/images/Franklin.jpg">
      <SectionBox>
        <Typography variant="h2">Die Franklin-Methode: Körper und Geist in Harmonie</Typography>
        <Typography variant="body1">
          Die Franklin-Methode ist eine ganzheitliche Herangehensweise, die Bewegung, mentale Vorstellungskraft und
          anatomisches Verständnis kombiniert, um das volle Potenzial deiner Beweglichkeit, Elastizität, Koordination
          und Leistungsfähigkeit zu verbessern.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Was ist die Franklin-Methode?</Typography>
        <Typography variant="body1">
          Die Franklin-Methode verwendet bildhafte Vorstellungen (Imagination) und Bewegungen, um die körperliche
          Funktion zu verbessern und positive Veränderungen im Körper zu bewirken. 1994 wurde das Institut für
          Imaginative Bewegungspädagogik in der Schweiz von Eric Franklin gegründet, welches später in Institut für
          Franklin-Methode® umbenannt wurde.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Die Wirkung der Franklin-Methode</Typography>

        <List
          items={[
            {
              title: "Mehr Beweglichkeit und Kraft",
              text: "Durch bildhafte Vorstellung und dem anatomischen Verständnis erlebst du, wie deine Beweglichkeit, Koordination und Kraft sich verbessern. Deine Muskeln und dein Bindegewebe werden elastischer, Gelenke geschmeidiger und du kannst die Bewegungsfunktionen gezielt und besser nutzen.",
            },
            {
              title: "Effizientes bewegen im Alltag",
              text: "Die Methode legt großen Wert auf die Integration der Verbesserungen in den Alltag sowie Tanz, Reitsport, Yoga, Pilates, für Schauspieler und Musiker. Die Bewegungen im Alltag haben den grössten Einfluss auf deine Skelettmuskulatur und Beweglichkeit. Du lernst die neuen Bewegungsmuster in deinen täglichen Aktivitäten einzubauen, um langfristige und nachhaltige Veränderungen zu erzielen.",
            },
            {
              title: "Steigerung der Leistungsfähigkeit",
              text: "Indem du die Funktionsweise deines Körpers besser verstehst und gezielt trainierst, kannst du deine sportliche Leistungsfähigkeit steigern und dein volles Potenzial ausschöpfen.",
            },
            {
              title: "Stressabbau",
              text: "Die Franklin-Methode umfasst auch mentale Techniken, die dabei helfen, Stress abzubauen und eine positive geistige Haltung zu fördern.",
            },
          ]}
        ></List>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Die Franklin-Methode: mit Leichtigkeit die Funktionen verbessern</Typography>

        <Typography variant="body1">
          Entdecke die transformative Kraft dieser einzigartigen Herangehensweise und erlebe wie elastisch, entspannt
          und effizient deine Bewegungen werden. Informiere dich noch heute über meine aktuellen Kursangebote. Ich freue
          mich darauf, dich auf deiner Reise zu einem gesünderen und bewussteren Körper zu begleiten!
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h3">Gruppenkurse und Workshops</Typography>

        <Typography variant="body1">
          In meinen Gruppenkursen und Workshops führe ich dich durch die Grundlagen und fortgeschrittenen Techniken
          dieser Methode. Du hast die Möglichkeit, in einer inspirierenden und unterstützenden Gruppenumgebung zu lernen
          und dich mit Gleichgesinnten auszutauschen.
        </Typography>
      </SectionBox>

      <CourseSection courses={courses}></CourseSection>
    </PageBox>
  )
}
