import { Box } from "@mui/material"

interface CardBoxProps {
  direction?: "column" | "row"
  children?: JSX.Element[]
}

export default function CardBox({ direction = "column", children }: CardBoxProps) {
  return <Box sx={{ display: "flex", flexDirection: direction, gap: 12 }}>{children}</Box>
}
