import { Box, Grid, Link, Typography } from "@mui/material"
import { useMemo } from "react"
import { ICourse } from "../interfaces/ICourse"
import { formatDate, formatDuration, isInPast } from "../utils/date"

export default function CourseBox({ course }: { course: ICourse }) {
  const noFutureOccurance = useMemo((): boolean => {
    return course.occurances.filter((o) => !isInPast(o)).length === 0
  }, [course])

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h3">{course.title}</Typography>
        {course.description.map((description, index) => (
          <Typography variant="body1" key={index} sx={{ mb: 2 }}>
            {description}
          </Typography>
        ))}
      </Box>

      <Grid container spacing={2}>
        {course.externalLink && course.externalLinkText && (
          <>
            <Grid item md={3}>
              Infos
            </Grid>
            <Grid item md={9}>
              <Link href={course.externalLink} target="_blank">
                {course.externalLinkText}
              </Link>
            </Grid>
          </>
        )}
        <Grid item md={3}>
          Durchführung
        </Grid>
        <Grid item md={9}>
          {course.type}
        </Grid>

        <Grid item md={3}>
          Ort
        </Grid>
        <Grid item md={9}>
          <Typography sx={{ fontWeight: 500 }}>{course.location.name}</Typography> {course.location.address}
        </Grid>

        <Grid item md={3}>
          Kursleitung
        </Grid>
        <Grid item md={9}>
          {course.person.map((p) => p.name).join(", ")}
        </Grid>

        {course.participants && (
          <>
            <Grid item md={3}>
              Teilnehmer
            </Grid>
            <Grid item md={9}>
              {course.participants} Teilnehmer
            </Grid>
          </>
        )}

        <Grid item md={3}>
          Preis
        </Grid>
        <Grid item md={9}>
          {course.price.amount && `${course.price.amount} CHF`}
          {course.price.amount && course.price.info ? ` / ${course.price.info}` : course.price.info}
        </Grid>

        {course.price.paymentType !== "voluntary" && (
          <>
            <Grid item md={3}>
              Bezahlungsart
            </Grid>
            <Grid item md={9}>
              {course.price.paymentType === "on-site" && "Bezahlung vor Ort oder Vorabüberweisung"}
              {course.price.paymentType === "cash" && "Bezahlung vor Ort"}
              {course.price.paymentType === "prepayment" && "Vorabüberweisung per Twint oder E-Banking"}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          {course.occurances.length > 1 ? "Durchführungen" : "Durchführung"}
        </Grid>
        <Grid item xs={12} md={9}>
          {noFutureOccurance ? (
            <Box sx={{ fontStyle: "italic" }}>Zurzeit sind keine Durchführungen geplant.</Box>
          ) : (
            <Box sx={{ px: { xs: 8, md: 0 } }}>
              {course.occurances.map((occurance, occuranceIndex) => (
                <Box key={occuranceIndex} sx={{ display: isInPast(occurance) ? "none" : "block" }}>
                  {formatDuration(occurance)} {occurance.info && `(${occurance.info})`}
                </Box>
              ))}
            </Box>
          )}
        </Grid>

        {course.registrationUntil && (
          <>
            <Grid item xs={12} md={3}>
              Anmelden bis
            </Grid>
            <Grid item xs={12} md={9}>
              {course.registrationUntil.date && formatDate(course.registrationUntil.date)}
              {course.registrationUntil.info && ` ${course.registrationUntil.info}`}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          Anmelden bei
        </Grid>
        <Grid item xs={12} md={9}>
          {course.externalRegistration ? (
            course.externalRegistration
          ) : (
            <>
              {course.person[0].email} {course.person[0].mobile ? `/ ${course.person[0].mobile}` : ""}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
