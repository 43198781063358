import { Button } from "@mui/material"
import { NavLink } from "react-router-dom"

export const NavButton = ({ to, label, fullWidth = false }: { to: string; label: string; fullWidth?: boolean }) => {
  return (
    <NavLink to={to}>
      <Button
        LinkComponent={"span"}
        disableRipple
        disableTouchRipple
        fullWidth={fullWidth}
        sx={{ color: "black", textTransform: "none", textAlign: "start", justifyContent: "start" }}
      >
        {label}
      </Button>
    </NavLink>
  )
}
