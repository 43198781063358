import { Box, Card, CardActionArea, Typography } from "@mui/material"

interface TeaserCardProps {
  children: JSX.Element | JSX.Element[]
  image?: string
  title?: string
  to: string
}

export default function TeaserCard({ children, image, title, to }: TeaserCardProps) {
  return (
    <Card
      elevation={1}
      sx={{
        borderRadius: { xs: 0, md: 10 },
      }}
    >
      <CardActionArea href={to} sx={{ pt: 8, pb: 6, px: { xs: 8, md: 10 }, textTransform: "none", color: "initial" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 8 }}>
          {image && (
            <Box
              sx={{
                borderRadius: 4,
                overflow: "hidden",
                alignSelf: "start",
                width: 128,
                height: 128,
                minWidth: 128,
              }}
            >
              <img src={image} width="128" height="128" alt={title}></img>
            </Box>
          )}

          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Typography variant="body1" sx={{ color: "primary.main", mt: 4 }}>
            Mehr erfahren
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}
