import { createTheme } from "@mui/material"
import { blue, deepOrange } from "@mui/material/colors"

let theme = createTheme({
  spacing: 4,
  palette: {
    primary: { main: blue[500] },
    secondary: { main: deepOrange[500] },
    background: { default: "#FFF" },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    h1: {
      fontSize: "28px",
      lineHeight: 1.5,
      fontWeight: 400,
      color: "rgb(0,0,0,.65)",
    },
    h2: {
      fontSize: "24px",
      lineHeight: 1.5,
      fontWeight: 400,
      color: "rgb(0,0,0,.85)",
      marginBottom: "8px",
    },
    h3: {
      fontSize: "20px",
      lineHeight: 1.5,
      fontWeight: 400,
      color: "rgb(0,0,0,.85)",
      marginBottom: "4px",
    },
    body1: {
      marginBottom: "4px",
    },
    body2: {
      marginBottom: "8px",
      borderLeft: `1px solid ${blue[500]}`,
      boxSizing: "border-box",
      paddingLeft: "8px",
    },
  },
})

theme = createTheme(theme, {
  typography: {
    h1: {
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    h3: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },
})

export default theme
