import { Box } from "@mui/material"
import { ReactNode } from "react"

interface SectionBoxProps {
  children: ReactNode
}

export default function SectionBox({ children }: SectionBoxProps) {
  return <Box sx={{ mb: 4, px: { xs: 4, sm: 8, md: 16 } }}>{children}</Box>
}
