import { Box, Typography } from "@mui/material"
import { blue } from "@mui/material/colors"

interface ListProps {
  items: { title: string; text: string }[]
}

export default function List({ items }: ListProps) {
  return (
    <>
      {items.map((item, index) => (
        <Box key={index} sx={{ display: "flex", mb: 2 }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              minWidth: "8px",
              backgroundColor: blue[800],
              borderRadius: 8,
              mt: 2,
              ml: 1,
            }}
          ></Box>
          <Box sx={{ ml: 4 }}>
            {item.title && (
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {item.title}
              </Typography>
            )}
            <Typography variant="body1">{item.text}</Typography>
          </Box>
        </Box>
      ))}
    </>
  )
}
