import IPerson from "../interfaces/IPerson"

export const monika: IPerson = {
  name: "Monika Helbling",
  image: "images/Monika_Helbling.jpeg",
  introductions: ["Bewegungspädagogin Franklin-Methode®", "Lehrerin Shibashi Qi-Gong"],
  email: "monika@helbling.io",
  mobile: "076 345 69 82",
}

export const christian: IPerson = {
  name: "Christian Frei",
  image: "images/Christian_Frei.jpg",
  introductions: [
    "Kontemplationslehrer via integralis seit 2019",
    "Theologe mit Erfahrung im Gemeindepfarramt und in der Spezialseelsorge",
    "Supervisor und Coach BSO",
  ],
  email: "freich@bluewin.ch",
  mobile: "079 611 55 15",
}

export const persons: IPerson[] = [monika, christian]
