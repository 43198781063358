import ILocation from "../interfaces/ILocation"

export const gsteigtobel: ILocation = {
  name: "Am Gsteigtobel",
  address: "Am Gsteigtobel 1, 8713 Uerikon",
  googleMaps: "https://goo.gl/maps/4KhHWvHkGaNJ44Zb6",
}

export const wieseKirche: ILocation = {
  name: "Wiese hinter der ref. Kirche Stäfa",
  address: "Kirchbühlstr. 40, Stäfa 8712",
  googleMaps: "https://goo.gl/maps/beDFVJHi4cJhcSdH8",
}

export const badiUerikon: ILocation = {
  name: "Badi 'Im Länder'",
  address: "Seestr. 272, 8713 Uerikon",
}

export const zoom: ILocation = {
  name: "Zoom",
  address: "",
}

export const gomnamo: ILocation = {
  name: "Gomnamo Yoga",
  address: "Oberfeldstrasse 6, 3985 Münster",
  googleMaps: "https://goo.gl/maps/jKrmqLVD6xbgHPVK9?coh=178572&entry=tt",
}

export const gelterkinden: ILocation = {
  name: "Sonnenhof (der Grandchamp-Schwestern)",
  address: "Badweg, 4460 Gelterkinden",
  googleMaps: "https://goo.gl/maps/SoJzZ6xxA4XW7H9P9",
}

export const kappel: ILocation = {
  name: "Kloster Kappel",
  address: "Kappelerhof 5, 8926 Kappel am Albis",
  googleMaps: "https://goo.gl/maps/CycwxN3Am7x6FQ2S9",
}

export const tertianum: ILocation = {
  name: "Tertianum",
  address: "Asylstrasse 18, 8810 Horgen",
  googleMaps: "https://goo.gl/maps/yWBn2q1cUbfYsAn26",
}

export const badiStaefa: ILocation = {
  name: "Badi Stäfa",
  address: "Strandbad Lattenberg, Seestrasse 2, 8712 Stäfa",
  googleMaps: "https://maps.app.goo.gl/9o7sSdotQ6PEWWaw5",
}

export const locations: ILocation[] = [gsteigtobel, tertianum, gelterkinden, kappel]
