import { format, isSameDay } from "date-fns"
import { de } from "date-fns/locale"
import { IDuration } from "../interfaces/IDuration"

export function formatDate(date: Date): string {
  return format(date, "dd.MM.yyyy", { locale: de })
}

export function formatTime(date: Date): string {
  return format(date, "HH:mm")
}

export function formatDuration(duration: IDuration): string {
  if (isSameDay(duration.start, duration.end)) {
    return `${formatDate(duration.start)} ${formatTime(duration.start)} - ${formatTime(duration.end)}`
  }

  return `${formatDate(duration.start)} ${formatTime(duration.start)} - ${formatDate(duration.end)} ${formatTime(
    duration.end
  )}`
}

export function isInPast(duration: IDuration): boolean {
  return duration.end < new Date()
}
