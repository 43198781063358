import { Box, Divider, Typography } from "@mui/material"
import { blue } from "@mui/material/colors"
import { ICourse } from "../interfaces/ICourse"
import CourseBox from "./CourseBox"
import SectionBox from "./SectionBox"

export default function CourseSection({ courses }: { courses: ICourse[] }) {
  return (
    <>
      <Divider sx={{ my: 8 }}></Divider>

      <SectionBox>
        <Typography variant="h2" color={blue[800]} sx={{ mb: 8 }}>
          Aktuelle Kurse
        </Typography>

        {courses.map((course, index) => (
          <Box key={index}>
            <CourseBox course={course}></CourseBox>
            {index < courses.length - 1 && <Divider sx={{ my: 12 }}></Divider>}
          </Box>
        ))}
      </SectionBox>
    </>
  )
}
