import { Box, Typography } from "@mui/material"
import { blue } from "@mui/material/colors"
import Appbar from "./Appbar"

interface PageBoxProps {
  title: string
  image?: string
  children?: JSX.Element | JSX.Element[]
}

export default function PageBox({ children, title, image }: PageBoxProps) {
  return (
    <Box sx={{ pb: { xs: 20, md: 30 }, mx: "auto" }} maxWidth="md">
      <Appbar></Appbar>

      <Box
        sx={{
          mb: 8,
          px: { xs: 4, sm: 8, md: 16 },
          py: 2,
          borderBottom: "1px solid",
          borderColor: blue[800],
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        {image && (
          <Box
            sx={{
              overflow: "hidden",
              alignSelf: "start",
              width: 64,
              height: 64,
              minWidth: 64,
            }}
          >
            <img src={image} width="64" height="64" alt={title}></img>
          </Box>
        )}
      </Box>

      {children}
    </Box>
  )
}
